export enum RoutesEnum {
  Home = '/',
  Bookings = '/bookings',
  EventBookings = '/bookings/events',
  ExperienceBookings = '/bookings/experiences',
  Events = '/events',
  ExternalEvents = '/events/external',
  Coupons = '/coupons',
  CouponList = '/coupons/list',
  CouponReport = '/coupons/report',
  BookingLogs = '/bookings/logs',
  Transactions = '/transactions',
  Statistics = '/statistics',
  Payouts = '/transactions/payouts',
  Payments = '/transactions/payments',
  Moderate = '/moderate',
  ModerateVideos = '/moderate/videos',
  ModerateVideoReports = '/moderate/video-reports',
  ModerateBookingReports = '/moderate/booking-reports',
  ModerateComments = '/moderate/comments',
  InvitationCode = '/invitations/:id',
  InvitationCodes = '/invitations/',
  Cms = '/cms',
  Banners = '/cms/banners',
  Users = '/users',
  Categories = '/cms/categories',
  HomePage = '/cms/homepage',
  SignIn = '/signin',
  Forbidden = '/forbidden'
}

export enum PricingModel {
  Hourly = 0,
  FixedPrice = 1
}

export enum BookingResolution {
  CustomerIsRight,
  ProviderIsRight,
  MutualAgreement
}

export enum BookingStatus {
  ProviderConfirmationNeeded = 0,
  ProviderDeclined = 1,
  ProviderConfirmed = 2,
  CustomerCanceled = 3,
  CustomerConfimationNeeded = 4,
  Finished = 5
}

export enum BookingType {
  Experience,
  Event
}

export enum VideoStatus {
  Unknown = 0,
  Accepted = 1,
  Review = 2,
  Rejected = 3
}

export enum LocationType {
  Non,
  CustomersLocation,
  MyLocation,
  Flexible
}

export enum CustomerReportReason {
  HarrasmentOrBullying = 0,
  HarmfulDesinformation = 1,
  HateSpeech = 2,
  ImpersonatingMe = 3,
  NudityOrPornography = 4,
  PrivateInformation = 5,
  SaleOrPromotionOfDrugs = 6,
  SaleOrPromotionOfFirearms = 7,
  SelfHarm = 8,
  ViolenceOrPhysicalAbuseThreat = 9,
  AdminDecline = 10,
  Spam = 11,
  Other = 12
}

export enum ProviderReportReason {
  NotAvailable,
  ChangeOfPlans,
  DontWantToDoIt,
  ChangePrice,
  HarrasmentOrBullying,
  HarmfulDesinformation,
  HateSpeech,
  ImpersonatingMe,
  NudityOrPornography,
  PrivateInformation,
  SaleOrPromotionOfDrugs,
  SaleOrPromotionOfFirearms,
  SelfHarm,
  ViolenceOrPhysicalAbuseThreat
}

export enum UserRoles {
  Unknown,
  SuperAdmin,
  Editor,
  Reviewer
}

export enum AdminCommentType {
  Booking,
  MediaFile,
  User
}

export enum SortType {
  Asc = 'asc',
  Desc = 'desc'
}
